import styled from 'styled-components';

import {
    TABLET_992, TABLET_768, MOBILE_460, DESKTOP_1200,
} from '../globals/sizes';
  
import credentialBg from '../../images/company-credentials/jpg/credential_bg.jpg'

import { COLORS } from '../globals/colors';  

export const HeroBackground = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: auto;
    margin-top: 90px;
`

export const HeroContainer = styled.div`
    height: 639px;
    width: 100%;
    max-width: 1500px;
    margin: auto;
    margin-top: 0px;
    overflow: hidden;
    position: relative;
    @media (max-width: ${TABLET_992}px) {
        height: unset;
    }

    @media (max-width: ${MOBILE_460}px) {
        height: unset;
    }

`;

export const HeroPicture = styled.picture`
  & > img, 
  & > source {
    width: 100%;
    height: 639px;
    object-fit: cover;
    object-position: right;
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: ${DESKTOP_1200}px) {
        object-position: 70%;
    }
    @media (max-width: ${TABLET_992}px) {
        width: 130vw;
        max-width: unset;
        height: unset;
        object-position: right;
    }
  }
`

export const InternalWrapper = styled.div`
  width: 100%;
  max-width: 1450px;
  margin: 0 auto;
  margin-left: 30px;

  @media (max-width: 1200px) {
    width: 95%;
  }

  @media (max-width: 992px) {
    background: white;
    max-width: 100%;
    width: 100%;
    flex: 1;
    z-index: 5;
  }
`


export const TextDiv = styled.div`

    @media (max-width: ${TABLET_992}px) {
        text-align: left;
        margin-top: 60vw;
        margin-left: auto;
        margin-right: auto;
        max-width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
    @media (max-width: ${TABLET_768}px) {
        max-width:90%;
    }
    @media (max-width: ${MOBILE_460}px) {
        margin-top: 101vw;
    }
`;

export const Title = styled.h1`
    color: ${COLORS.redTitle};
    font-size: 40px;
    line-height: 48px;
    font-family: 'Roboto';
    margin-top: 110px;
    width: 300px;

    & > span {
        white-space: nowrap;
    }

    @media (max-width: ${TABLET_992}px) {
        margin-top: 6vw;
        width: 100%;
    }

    @media (max-width: ${MOBILE_460}px) {
       margin-top: 10vw;
    }

`;

export const IntroductionDiv = styled.div`
    color: #404040;
    margin-top: 24px;
    width: 35%;
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
    font-family: 'Roboto';
    @media (max-width: ${DESKTOP_1200}px) {
        font-size: 20px;
    }
    @media (max-width: ${TABLET_992}px) {
        width: 100%;
        margin-top: 14px;
        margin-bottom: 30px;
    }
    @media (max-width: ${TABLET_768}px) {
        width: 80%;
        align-self: flex-start;
    }
    @media (max-width: ${MOBILE_460}px) {
        line-height: 26px;
        font-weight: 300;
        font-size: 19px;
        width: 90%;
    }
`;

export const GridPanelContainer = styled.div`
    text-align: center;
    
    @media (max-width: ${TABLET_992}px) {
        width: 100%;
        max-width: 100%;
    }
`;

export const GridPanel = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1500px;
    margin: auto;
    min-height: 300px;

    @media (max-width: ${TABLET_992}px) {
        grid-template-columns: 100%;
        width: 100%;
        max-width: 100%;
    }
`;

export const GridPiture = styled.picture`
    & > img, 
    & > source {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (max-width: ${TABLET_992}px) {
        grid-row-start: 1;
    }
`;

export const GridTextContainer = styled.div`
    background: transparent linear-gradient(270deg, #E01A73 0%, #E0281A 100%) 0% 0% no-repeat padding-box;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 60px;
    text-align: left;
    @media (max-width: ${TABLET_992}px) {
        padding: 40px;
    }
    @media (max-width: ${MOBILE_460}px) {
        padding-left: 0;
        padding-right: 0;
        & > * {
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

export const GridTitle = styled.h3`
    position: relative;
    font-size: 2rem;
    line-height: 3rem;
    font-family: 'Roboto';
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 20px;
    &::before {
        content: "";
        width: 120px;
        height: 3px;
        background: white;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    @media (max-width: ${TABLET_992}px) {
        font-size: 1.6rem;
        line-height: 2rem;
    }
`;

export const GridText = styled.p`
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-family: 'Roboto';
`;

export const CredentialsBackground = styled.div`
    background: url(${credentialBg});
    padding: 20px 0;
    text-align: center;
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: ${MOBILE_460}px) {
        text-align: left;
    }
`;

export const CredentialsContainer = styled.div`
    width: fit-content;
    max-width: 1100px;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-gap: 100px;

    @media (max-width: ${TABLET_768}px) {
        grid-gap: 10px;
    }

    @media (max-width: ${MOBILE_460}px) {
        grid-template-columns: 1fr;
        grid-gap: 25px;
        width: 90%;
    }
`

export const CredentialInfo = styled.div`
    color: ${COLORS.redTitle};
    text-align: center;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: ${MOBILE_460}px) {
        display: block;
        text-align: left;
        width: 100%;
        max-width: 100%;
    }
`;

export const CredentialTitle = styled.p`
    font-size: 25px;
    line-height: 30px;
    font-weight: bold;
    font-family: 'Roboto';
    text-transform: uppercase;
    @media (max-width: ${TABLET_768}px) {
        font-size: 22px;
    }

    @media (max-width: ${MOBILE_460}px) {
        position: relative;
        line-height: 60px;
        &::before {
            content: "";
            width: 120px;
            height: 3px;
            background: ${COLORS.redTitle};
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
`;

export const CredentialText = styled.p`
    font-size: 13px;
    line-height: 20px;
    font-family: 'Roboto';
    margin-top: 10px;
`;

export const CredentialPicture = styled.picture`
    width: 100%;
    text-align: center;

    @media (max-width: ${MOBILE_460}px) {
        text-align: left;
    }

    & > img, 
    & > source {
        height: 150px;
        margin: auto;
        align-self: center;

        @media (max-width: ${TABLET_768}px) {
            height: 100px;
        }

        @media (max-width: ${MOBILE_460}px) {
            margin-bottom: 25px;
        }
    }
`;